import {controller, target} from '@github/catalyst'
import type {QueryBuilderElement} from '@github-ui/query-builder-element'
@controller
class DependabotAlertRuleFormElement extends HTMLElement {
  @target declare name: HTMLInputElement
  @target declare rule: HTMLInputElement
  @target declare ruleOptions: HTMLElement
  @target declare updateRule: HTMLInputElement

  get #dependabotUpdatesEnabled() {
    return this.querySelector<HTMLInputElement>('input[name=dependabot_updates_enabled]')?.value === 'true'
  }

  get #selectedRuleOption() {
    return this.ruleOptions.querySelector<HTMLInputElement>('input:checked')
  }

  get #untilPatchRuleOption() {
    return this.ruleOptions.querySelector<HTMLInputElement>('input[value=until_patch]')
  }

  connectedCallback() {
    this.toggleRuleCheckbox()
    this.focusAlertCriteriaIfInvalid()
  }

  toggleRuleCheckbox() {
    if (!this.rule.checked) {
      if (this.#selectedRuleOption?.value !== 'until_patch' && !this.#dependabotUpdatesEnabled) {
        this.#untilPatchRuleOption?.click()
      }
      this.ruleOptions.hidden = true
    } else {
      this.ruleOptions.hidden = false
    }
    this.selectedRuleOptionDidChange()
  }

  selectedRuleOptionDidChange() {
    // If Dependabot security updates are enabled, the updateRule checkbox is always checked and disabled,
    // so we can ignore changes to the selected rule option.
    // If `updateRule` is missing, it's because the form is not being rendered
    if (this.#dependabotUpdatesEnabled || !this.updateRule) {
      return
    }
    if (this.#selectedRuleOption?.value !== 'until_patch') {
      this.updateRule.checked = false
      this.updateRule.disabled = true
    } else {
      this.updateRule.disabled = false
    }
  }

  focusAlertCriteriaIfInvalid() {
    const queryBuilderInput = this.querySelector('query-builder input') as HTMLInputElement
    if (queryBuilderInput.hasAttribute('invalid')) {
      // for the moment the nameField is the only field that is before this field, therefore
      // we can assume that the queryBuilder is the first field that is invalid if the nameField is valid
      if (!this.name.hasAttribute('invalid')) {
        // we are (ab)using the `query-builder:request-provider` event to determine when the query builder
        // is ready to be focused as it's not fully initialized during this callback
        // http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://github.com/github/dependabot-alerts/issues/1508
        let focused = false
        document.addEventListener('query-builder:request-provider', (event: Event) => {
          // return unless the event is for our our query builder
          const eventTarget = event.target as QueryBuilderElement
          if (!eventTarget || eventTarget.input.id !== queryBuilderInput.id) return

          // since this event can be fired multiple times, only focus on the first
          if (!focused) {
            focused = true
            queryBuilderInput.focus()
          }
        })
      }
    }
  }
}
